<template>
	<van-popup v-model="visible" round closeable position="bottom" get-container="body" :style="{ height: '80%' }">
		<div class="main-wrap-popup">
			<div class="main-header"><div class="title">借用详情</div></div>

			<div class="main-container layout-1">
				<div class="row-price">
					<p class="label">总费用（元）</p>
					<p class="value">{{ orderData.amount || '-' }}</p>
				</div>
				<div class="row-3">
					<div class="title">{{ orderData.name }}</div>
					<div class="row-wrap">
						<div class="left-img"><img class="img" :src="$replacePhoto(imgUrl)" alt="" /></div>
						<div class="right-list">
							<div class="list-item">
								<span class="label">{{$VUEAPPDEVICECODE()}}</span>
								<span class="value">{{ orderData.code }}</span>
							</div>
							<div class="list-item">
								<span class="label">起租日期</span>
								<span class="value">{{ $getDate(orderData.startTime) }}</span>
							</div>
							<div class="list-item">
								<span class="label">订单费用</span>
								<span class="value">{{ orderData.amount }}{{ $formatBillingType1(orderData.billingType).unit }}</span>
							</div>
							<div class="list-item">
								<span class="label">计费方式</span>
								<span class="value">{{ $formatBillingType1(orderData.billingType).name }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-4">
					<div class="list-item">
						<span class="label">订单编号</span>
						<span class="value">{{ orderData.orderId }}</span>
					</div>
					<div class="list-item">
						<span class="label">下单时间</span>
						<span class="value">{{ formatDateTime(orderData.orderTime) }}</span>
					</div>
					<div class="list-item">
						<span class="label">付款时间</span>
						<span class="value">{{ formatDateTime(orderData.orderTime) }}</span>
					</div>
					<div class="list-item">
						<span class="label">订单状态</span>
						<span class="value">{{ formatStatusLabel(orderData.orderStatus) }}</span>
					</div>
				</div>
			</div>
		</div>
	</van-popup>
</template>

<script>
import { formatStatusLabel } from '@/baseConfig/orderStatusOptions/index.js';
export default {
	name: 'detailPopup',
	components: {},
	computed: {
		imgUrl() {
			if (this.$valueIsExist(this.orderData, 'photo') && this.orderData['photo'] != '') {
				let photo = this.orderData['photo'];
				return `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}&scale=0.3`;
			}
			return this.$replacePhoto('');
		}
	},
	data() {
		return {
			formatStatusLabel: formatStatusLabel,
			visible: false,
			orderData: {}
		};
	},
	created() {},
	mounted() {},
	methods: {
		getChinese(strValue) {
			if (strValue != null && strValue != '' && strValue != '-') {
				var reg = /[\u4e00-\u9fa5]/g;
				return strValue.match(reg).join('');
			} else {
				return '';
			}
		},
		orderDetail() {
			this.$api.deviceBorrowModule
				.orderDetail({
					id: this.id
				})
				.then(res => {
					this.orderData = res.data;
				})
				.catch(e => {});
		},
		show(id) {
			this.visible = true;
			this.id = id;
			this.orderDetail();
		},
		hide() {
			this.visible = false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.main-wrap-popup {
	background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
	background-size: 100% 195px;
	background-repeat: no-repeat;
	.main-header {
		.title {
			color: #fff;
		}
	}
	.main-container {
		padding-top: 20px;
		.row-price {
			background: #3e73fb;
			box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
			border-radius: 12px;
			padding: 12px;
			color: #fff;
			.label {
				font-size: 12px;
			}
			.value {
				font-size: 24px;
				margin-top: 8px;
			}
		}
	}
}
</style>
