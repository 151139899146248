<template>
  <div class="reservation"  ref="reservationContent">
    <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
      <div class="main-wrap" >
        <div class="card-list" ref="cardList" v-show="tableData.length > 0">
          <listItem2
            v-for="(item, index) in tableData"
            :key="index"
            :itemData="item"
            :config="listItemConfig"
            @click="toDetail(item)"
          >
            <template slot="titleRight">
              <span
                :style="{ color: formatStatusData(item.orderStatus).color }"
                >{{ formatStatusData(item.orderStatus).label }}</span
              >
            </template>
            <div class="row-footer" slot="footer">
              <!-- <button
                type="button"
                class="button-plan button-plan-primary"
                @click="toDetail(item)"
              >
                借用详情
              </button> -->
            </div>
          </listItem2>
        </div>
        <div v-show="tableData.length == 0" class="emptyView">
          <KcEmpty description="暂无数据"></KcEmpty>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import listItem2 from "@components/listItem/listItem2";
import { getDate } from "@utils/utils.js";
import { formatStatusData } from "@/baseConfig/orderStatusOptions/index.js";
export default {
  name: "deviceRecord",
  components: { listItem2 },
  inject: ["deviceRecordBaseData"],
  props: {
    queryType: {
      type: [String, Number],
      default: "",
    },
    listItemConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isRefresh: false,
      formatStatusData: formatStatusData,
      page: 1,
      pageSize: 30,
      totalCount:0,
      tableData: [],
      scrollbarEl: "",
      filterTimeOptions: [
        {
          label: "近一周",
          startTime: new Date(
            getDate(new Date().getTime() - 7 * 8.64e7) + " 00:00:00"
          ).getTime(),
          endTime: new Date(getDate() + " 23:59:59").getTime(),
        },
        {
          label: "近两周",
          startTime: new Date(
            getDate(new Date().getTime() - 14 * 8.64e7) + " 00:00:00"
          ).getTime(),
          endTime: new Date(getDate() + " 23:59:59").getTime(),
        },
        {
          label: "近1个月",
          startTime: new Date(
            getDate(new Date().getTime() - 30 * 8.64e7) + " 00:00:00"
          ).getTime(),
          endTime: new Date(getDate() + " 23:59:59").getTime(),
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.$bus.on("deviceRecordSearch", this.searchEvent);
    this.getList();
    this.scrollbarEl = this.$refs.reservationContent;
    this.scrollbarEl.onscroll = () => {
      this.scrollEvent();
    };
  },
  beforeDestroy() {
    this.$bus.off("deviceRecordSearch", this.searchEvent);
  },
  methods: {
    scrollEvent() {
      let scrollTop = this.scrollbarEl.scrollTop;
      let offsetHeight = this.scrollbarEl.offsetHeight;
      let scrollHeight = this.scrollbarEl.scrollHeight;
      if (
        scrollTop + offsetHeight == scrollHeight &&
        this.totalCount > this.tableData.length
      ) {
        this.page += 1;
        this.loadMore = true;
        this.getList();
      }
    },
    searchEvent(){
      this.page = 1
      this.tableData = []
      this.getList()
    },
    onRefresh() {
      this.isRefresh = true;
      this.getList(() => {});
    },
    getList() {
      let _startTime = "";
      let _endTime = "";
      let _date = this.deviceRecordBaseData.date;
      let _index = this.deviceRecordBaseData.activeFilterTimeIndex;
      if (_date != "" && _date[0] && _date[1]) {
        _startTime = new Date(
          this.$getDate(_date[0]) + " " + "00:00:00"
        ).getTime();
        _endTime = new Date(
          this.$getDate(_date[1]) + " " + "23:59:59"
        ).getTime();
      }
      if (typeof _index == "number") {
        _startTime = this.filterTimeOptions[_index].startTime;
        _endTime = this.filterTimeOptions[_index].endTime;
      }
      this.$api.deviceBorrowModule
        .deviceOrderList({
          query: this.deviceRecordBaseData.query,
          startTime: _startTime,
          endTime: _endTime,
          page: this.page,
          pageSize: this.pageSize,
          queryType: this.queryType,
          isTenantIdChild:1,
        })
        .then((res) => {
          this.loadMore = false;
          this.loading = false;
          this.isRefresh = false;
          this.totalCount = res.totalCount;
          res.data.map((item) => {
            item.orderTimeText = this.$filters.formatDateTime(item.orderTime);
            item.startTimeText = this.$filters.formatDateTime(item.startTime);
            item.endTimeText = this.$filters.formatDateTime(item.endTime);
          });
          if (this.page == 1) {
            this.tableData = res.data;
          } else {
            this.tableData.push(...res.data);
          }
        })
        .catch((res) => {
          this.isRefresh = false;
          this.loading = false;
          this.loadMore = false;
        });
    },
    formatStatus(value) {
      const result = this.statusOptions.filter((item) => {
        return item.value == value;
      });
      return result.length ? result[0] : {};
    },
    toDetail(item) {
      this.$emit("toDetail", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";
@import "@styles/layout.scss";
.reservation {
  height: 100%;
  overflow: auto;
  .main-wrap {
    overflow: auto;
  }
  .emptyView {
    height: 360px;
  }
}
</style>
